import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router'

import PatternsScreen from '../screens/PatternsScreen'
import UploadScreen from '../screens/UploadScreen'
import EditorScreen from '../screens/EditorScreen'
import ExtractScreen from '../screens/ExtractScreen'
import FinishScreen from '../screens/FinishScreen'


export default function PatternRoute () {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}/new`}>
        <UploadScreen rootPath={match.path} />
      </Route>
      <Route exact path={`${match.path}/editor/:id`}>
        <EditorScreen nextPath={match.path} />
      </Route>
      <Route exact path={`${match.path}/extract/:id`}>
        <ExtractScreen nextPath={`${match.path}/finish`} />
      </Route>
      <Route exact path={`${match.path}/finish/:id`}>
        <FinishScreen />
      </Route>
      <Route exact path={`${match.path}`}>
        <PatternsScreen />
      </Route>
    </Switch>
  )
}
