import React, { useEffect } from 'react'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialLink from '@material-ui/core/Link';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import AttachmentIcon from '@material-ui/icons/Attachment';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridOnIcon from '@material-ui/icons/GridOn';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import Screen from '../components/wrappers/Screen';

import api from '../api'
import { CircularProgress } from '@material-ui/core';
import { setDataPoints } from '../store/reducers/patternReducer'

function ExtractScreen ({ nextPath = '/finish' }) {
  let { id } = useParams();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const reduxId = useSelector(state => state.pattern.patternId)
  const logged = useSelector(state => state.auth.logged)
  const dataPoints = useSelector(state => state.pattern.dataPoints)
  // Route params ID takes priority over redux id
  const patternId = id || reduxId

  const [expanded, setExpanded] = React.useState('spreadsheet');
	const [fileList, setFileList] = React.useState([])
	const [search, setSearch] = React.useState('')
	const [selectedPatternId, setPatternId] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [patterns, setPatterns] = React.useState([]);

  const changeSelectedPattern = (id) => {
    setPatternId(id);
    patterns.forEach(pattern => {
      if (pattern.key === id) {
        dispatch(setDataPoints(pattern.points))
      }
    })
  }

  useEffect(() => {
    const fetchPatterns = async () => {
      setLoading(true)
      try {
        const result = await api.get('/patterns/me', { dispatch })
        if (result.data.patterns.length > 0) {
          setPatterns(result.data.patterns)
          changeSelectedPattern(result.data.patterns[0].key)
        } else {
          enqueueSnackbar('You don\'t have any document patterns yet. Go to "Patterns" to create one');
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong...')
      } finally {
        setLoading(false)
      }
    }
    if (logged) {
      setPatternId(patternId || '')
      fetchPatterns()
    }
  // eslint-disable-next-line
  }, [])

	const handleFile = (files) => {
		if (files) {
			setFileList([...fileList, ...files])
		}
	}

	const handleRemoveFile = (index) => {
		let copy = [...fileList]
		copy.splice(index, 1)
		setFileList([...copy])
	}

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelect = (event) => {
    changeSelectedPattern(event.target.value)
  }

  const getPatternId = () => {
    return id || reduxId || selectedPatternId
  }

	if (!patternId && !logged) {
		return (
			<Redirect to={{ pathname: '/upload' }} />
		)
	}

	const renderNextButton = () => {
		if (fileList.length <= 0) {
			return (
				<Button
					style={{ marginTop: '2rem' }}
					color="primary"
					variant="contained"
					disabled
				>
					EXTRACT
				</Button>
			)
		}

		return (
			<Link to={{
				pathname: `${nextPath}/${getPatternId()}`,
				state: { files: fileList, step: 3 }
			}}>
				<Button
					style={{ marginTop: '2rem', width: '100%' }}
					color="primary"
					variant="contained"
				>
					EXTRACT
				</Button>
			</Link>
		)
	}

  const renderList = () => {
    if (fileList.length <= 0) {
      return (
        <div>
          <span>No files selected...</span>
        </div>
      )
    }

    return fileList.map(({ name }, index) => {
      const visible = search && search !== '' ? name.toLowerCase().includes(search.toLowerCase()) : true
      if (!visible) {
        return <div key={index}></div>
      }
      return (
        <ListItem key={index}>
          <ListItemIcon>
            <InsertDriveFileIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            {name}
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )
    })
  }

  const renderSelect = () => {
    if (!logged) return null;

    return (
      <div className="flex flex-col w-full mb-6">
        <h4>
          Select the extraction pattern to use
        </h4>
        <div className="flex flex-row gap-4 flex-1 items-baseline px-6">
          <div className="mt-4">
            <FindInPageIcon className="primary" style={{ fontSize: 40 }} />
          </div>
          <FormControl className="w-96" error={!Boolean(selectedPatternId)}>
            {/* <InputLabel id="select-label">Document Pattern</InputLabel> */}
            <Select
              labelId="select-label"
              id="document-pattern-select"
              value={selectedPatternId}
              onChange={handleSelect}
              fullWidth
            >
              {patterns.map(pattern => {
                return <MenuItem key={pattern.key} value={pattern.key}>{pattern.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <Screen>
        <div className="flex flex-row justify-center p-5 my-10">
          <CircularProgress size="5rem" />
        </div>
      </Screen>
    )
  }

	return (
    <Screen>
      <div className="flex flex-col gap-1">
        <div className="grid grid-cols-2 gap-4 my-1 mx-3 justify-start max-h-inherit">
          <div className="w-full flex-1 max-h-inherit">
            <div className="flex flex-col gap-1 max-h-inherit p-4">
              <h1 className="text-3xl">
                Choose files to extract
              </h1>
              {renderSelect()}
              <h4 className="text-base">
                Upload all files you want to extract using that document template.
              </h4>
              <div className="mt-4">
                <Button
                  style={{ width: "100%", height: "5rem" }}
                  variant="outlined"
                  color="primary"
                  startIcon={<AttachmentIcon fontSize="large" />}
                  component="label"
                >
                  <input type="file" hidden accept=".pdf" onChange={(e) => handleFile(e.target.files)} multiple />
                  UPLOAD FILES
                </Button>
              </div>
              {fileList.length > 0 && (
                <div className="mt-2">
                  <TextField
                    id="search"
                    className="w-full"
                    label="Filter"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }
                    }
                  />
                </div>
              )}
              <div className="flex flex-col gap-2 max-h-52 w-full overflow-y-auto overflow-x-hidden p-1 my-3">
                <List component="nav" aria-label="data-points">
                  {renderList()}
                </List>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.goBack()}
                >
                  GO BACK
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1 max-h-inherit">
            <div className="py-4">
              <h1 className="text-3xl">
                Choose output format
              </h1>
            </div>
            <div className="flex flex-col mb-2">
              <span>
                Didn't find the output type you're looking for?
              </span>
              <MaterialLink href="#" variant="body1">
                Request here
              </MaterialLink>
            </div>
            <Accordion expanded={expanded === 'spreadsheet'} onChange={handleChange('spreadsheet')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <div className="flex flex-row gap-2 items-center">
                  <GridOnIcon fontSize="large" style={{ color: 'green' }} />
                  <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                    Spreadsheet
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-2 no-wrap">
                  <span>
                    Extract data from PDFs to a spreadsheet.
                    <br />
                    Each row is a different file. Each column is the data point extracted from that file.
                  </span>
                  <div>
                    <h4 className="text-xl">
                      Preview
                    </h4>
                    <div className="mt-4 mx-auto shadow overflow-auto max-w-sm max-h-32 border-gray-200 sm:rounded-lg">
                      <table className="border-collapse table-fixed text-sm">
                        <thead className="bg-gray-50">
                          <tr className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                            <th className="w-8 py-2 px-3">
                              File name
                            </th>
                            {dataPoints && dataPoints.map((point, index) => {
                              return (
                                <th className="w-8 py-2 px-3" key={index}>
                                  {point.name}
                                </th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {fileList.map((file, index) => {
                            return (
                              <tr key={index}>
                                <td class="px-2 py-1 text-left whitespace-nowrap">
                                  {file.name}
                                </td>
                                {dataPoints && dataPoints.map((_, index) => {
                                  return (
                                    <td key={index} class="px-2 py-1 text-center whitespace-nowrap">
                                      ...
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>                      
                    </div>
                  </div>
                  {renderNextButton()}
                </div>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion expanded={expanded === 'sql'} onChange={handleChange('sql')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <div className="flex flex-row gap-2 items-center">
                  <StorageIcon fontSize="large" style={{ color: 'blue' }} />
                  <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                    SQL Database
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-2 no-wrap">
                  <Typography color="textSecondary">
                    The click event of the nested action will propagate up and expand the accordion unless
                    you explicitly stop it.
                  </Typography>
                  {renderNextButton()}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'zapier'} onChange={handleChange('zapier')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <div className="flex flex-row gap-2 items-center">
                  <img className="mr-1" src={zapierSvg} height="32" width="32" alt="zapier logo" />
                  <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                    Zapier
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="flex flex-col gap-2 no-wrap">
                  <Typography color="textSecondary">
                    The click event of the nested action will propagate up and expand the accordion unless
                    you explicitly stop it.
                  </Typography>
                  {renderNextButton()}
                </div>
              </AccordionDetails>
            </Accordion> */}
          </div>
        </div>
      </div>
    </Screen>
	)
}

export default ExtractScreen;
