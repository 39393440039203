import { combineReducers } from 'redux'

import patternReducer from "./patternReducer"
import authReducer from "./authReducer"

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  pattern: patternReducer,
  auth: authReducer
})

export default rootReducer
