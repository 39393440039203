import React from 'react'
import { Redirect, Route } from 'react-router'
import { useSelector } from 'react-redux'

function PrivateRoute (props) {
  const { logged } = useSelector(state => state.auth)

  if (!logged) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  return (
    <Route {...props}>
      {props.children}
    </Route>
  )
}

export default PrivateRoute;
