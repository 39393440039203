import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import UploadScreen from '../screens/UploadScreen'
import EditorScreen from '../screens/EditorScreen'
import ExtractScreen from '../screens/ExtractScreen'
import FinishScreen from '../screens/FinishScreen'
import LoginScreen from '../screens/LoginScreen'

import NotFound from '../screens/NotFound'
import HooksScreen from '../screens/HooksScreen'

import PrivateRoute from '../components/wrappers/PrivateRoute'
import PatternRoute from './PatternRoute'

function Router () {
  const { logged } = useSelector(state => state.auth);

	return (
		<Switch>
			<Route exact path="/finish/:id">
				<FinishScreen />
			</Route>
			<Route exact path="/extract">
				<ExtractScreen nextPath="/finish" />
			</Route>
			<Route exact path="/editor/:id">
				<EditorScreen nextPath="/extract" />
			</Route>
			<Route exact path="/upload">
				<UploadScreen />
			</Route>
			<Route exact path="/login">
				<LoginScreen />
			</Route>
      <PrivateRoute path="/patterns">
        <PatternRoute />
      </PrivateRoute>
      <PrivateRoute exact path="/hooks">
        <HooksScreen />
      </PrivateRoute>
			<Route exact path="/">
				{logged ? <Redirect to={{ pathname: '/patterns' }} /> : <Redirect to={{ pathname: '/upload' }} />}
			</Route>
      <Route path="*">
        <NotFound />
      </Route>
		</Switch>
	)
}

export default Router
