import React from 'react'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import BuildIcon from '@material-ui/icons/Build';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Screen from '../components/wrappers/Screen';


export default function HooksScreen () {
  return (
    <Screen>
      <div className="mx-auto">
        <div className="p-6 flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <h1 className="text-3xl">
                Hooks
              </h1>
              <h4 className="text-base">
                Create integrations to automatically extract data from pdfs
              </h4>
            </div>
            <div className="flex flex-col justify-center items-center">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                NEW HOOK
              </Button>
            </div>
          </div>
          <div className="flex-col mt-8">
            <List>
              <ListItem button>
                <ListItemAvatar>
                  <BuildIcon style={{ color: 'gray' }} />
                </ListItemAvatar>
                <ListItemText>
                  <span className="text-xl">
                    My Hook
                  </span>
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    </Screen>
	)
}