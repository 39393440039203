import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    logged: false,
    token: null,
    user: null
  },
  reducers: {
    logUser(state, action) {
      state.logged = true
      state.user = action.payload.user
      state.token = action.payload.token
    },
    logout(state) {
      localStorage.removeItem('pdfisher_token')
      state.logged = false
      state.user = null
      state.token = null
    }
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice
// Extract and export each action creator by name
export const { logUser, logout } = actions
// Export the reducer, either as a default or named export
export default reducer
