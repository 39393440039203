import React from 'react'

import Typography from '@material-ui/core/Typography'

import Screen from '../components/wrappers/Screen'
import notFoundSvg from '../assets/not_found.svg'

export default function NotFound () {
  return (
    <Screen>
      <div className="flex flex-col mt-12 gap-8 justify-center items-center mx-auto">
        <img src={notFoundSvg} alt="not found" height="400" width="400" />
        <Typography variant="h2">
          Nothing here...
        </Typography>
      </div>
    </Screen>
  )
}
