import React from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import ScrollContainer from 'react-indiana-drag-scroll';

import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListSubheader from '@material-ui/core/ListSubheader'

import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import CreateIcon from '@material-ui/icons/Create'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { clearPattern, setDataPoints, setDataQuery } from '../store/reducers/patternReducer';

import { BACKEND_URL } from '../api';
import api from '../api'


class EditorScreen extends React.Component {
	static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  isDirty = false;
  isDrag = false;
  startX = -1;
  startY = -1;
  curX = -1;
  curY = -1;
	parentRef = null;
	canvasRef = null;
  ctx = null;
	width = 1;
	height = 1;

  constructor(props) {
    super(props);
		this.state = {
			loading: false,
			textModal: false,
			goNext: false,
			name: 'My Document Pattern',
      lang: 'en',
			textInput: '',
			numPages: 1,
			pageNumber: 1,
			scale: 1,
			editing: null,
			lastAdded: null
		}
  }
  
  componentDidMount() {
    this.addMouseEvents()
    this.getPattern()
  }

  getPatternId () {
    const { match } = this.props;
    if (match.params && match.params.id) {
      return match.params.id
    }
    return this.props.patternId
  }

  async getPattern () {
    const patternId = this.getPatternId()
    this.setState({ loading: true })
    try {
      const result = await api.get('/patterns/' + patternId)
      const { pattern } = result.data;
      this.setState({
        name: pattern.name,
        loading: false
      })
      this.props.setDataPoints(pattern.points)
      this.queryDataPoints()
    } catch (e) {
      console.warn(e)
      this.setState({ loading: false })
    }
  }

	startCanvas (secondAttempt = false) {
		if (!this.canvasRef) {
			console.warn('tried to startCanvas without canvasRef')
			return
		}
		const elements = document.getElementsByClassName("react-pdf__Page__canvas")
		if (elements && elements.length > 0) {
			this.width = elements[0].width;
			this.height = elements[0].height;
			this.canvasRef.width = this.width;
			this.canvasRef.height = this.height;
		} else {
			if (!secondAttempt) {
				setTimeout(() => {
					console.warn('trying again')
					this.startCanvas(true)
				}, 50)
			}
		}
	}

  updateCanvas = () => {
		const { scale, pageNumber } = this.state
		const ctx = this.canvasRef.getContext('2d')
    ctx.clearRect(0, 0, 3000, 3000)
		console.log('update canvas')

		// Draw selection
    ctx.strokeStyle = '#00F'
		ctx.fillStyle = '#00F'
    ctx.lineWidth = scale < 1 ? 2 : 4
		ctx.font = "bold 15px Arial"
		this.props.dataPoints.forEach(({ name, bbox, page }) => {
			if (bbox && page === pageNumber) {
        console.log('drawing point ', name, ' because point page ', page, ' equals ', pageNumber)
				ctx.fillText(name, bbox[0] * scale, (bbox[1] - 5) * scale)
				ctx.strokeRect(
					bbox[0] * scale,
					bbox[1] * scale,
					(bbox[2] - bbox[0]) * scale,
					(bbox[3] - bbox[1]) * scale
				)
			}
		})

    if (this.isDrag) {
      requestAnimationFrame(this.updateCanvas)
    }
    if (!this.isDrag) {
      return
    }

		// Draw Dragging Box
    ctx.strokeStyle = '#F00'
    ctx.lineWidth = 2

    if (this.isDrag) {
      const rect = {
        x: this.startX,
        y: this.startY,
        w: this.curX - this.startX,
        h: this.curY - this.startY,
      }
      ctx.strokeRect(
				rect.x,
				rect.y,
				rect.w,
				rect.h
			)
    }  
    this.isDirty = false
  };

  componentWillUnmount() {
    this.removeMouseEvents()
  }

  addMouseEvents() {
    document.addEventListener('mousedown', this.onMouseDown, false);
    document.addEventListener('mousemove', this.onMouseMove, false);
    document.addEventListener('mouseup', this.onMouseUp, false);
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  removeMouseEvents() {
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mousemove', this.onMouseMove, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

	onKeyDown = (e) => {
		if (e.key === 'Escape') {
			this.setState({ editing: false })
			e.preventDefault()
		}
	}

  onMouseDown = (e) => {
		if (e.button !== 0) return;
		if (this.state.editing !== 'bbox') return;
		const bounding = document.getElementById('drawing').getBoundingClientRect();
		if (e.clientX < bounding.x || 
			e.clientY < bounding.y ||
			e.clientX > bounding.x + bounding.width || 
			e.clientY > bounding.y + bounding.height) {
			return
		}
    this.isDrag = true
    this.curX = this.startX = e.offsetX
    this.curY = this.startY = e.offsetY
    requestAnimationFrame(this.updateCanvas)
  };

  onMouseMove = (e) => {
    if (!this.isDrag) return;
		if (this.state.editing !== 'bbox') return;
    this.curX = e.offsetX
    this.curY = e.offsetY
    this.isDirty = true
  };
  
  onMouseUp = (e) => {
		if (e.button !== 0) return;
		if (this.state.editing === 'bbox') {			
			const rect = {
				x: Math.min(this.startX, this.curX),
				y: Math.min(this.startY, this.curY),
				w: Math.abs(e.offsetX - this.startX),
				h: Math.abs(e.offsetY - this.startY),
			}
			this.onSelected(rect)
		} else if (this.state.editing === 'label') {
			const selectedText = window.getSelection().toString()
			console.log('TODO')
			console.log(selectedText)
		}
		this.startX = -1
		this.startY = -1
		this.curX = -1
		this.curY = -1
		this.isDrag = false
		this.isDirty = false
  };

	updateDataPoint(index, content) {
		setTimeout(() => {
			requestAnimationFrame(this.updateCanvas)
		}, 500)
		return this.props.dataPoints.map((dataPoint, idx) => {
			return (idx === index) ? {
					...dataPoint,
					...content
				} : dataPoint
		})
	}

	onSelected(drawRect) {
		if (drawRect.w === 0 || drawRect.h === 0 || drawRect.x < 0 || drawRect.y < 0)
			return;
		const { scale } = this.state;
		const rect = {
			x: parseInt(drawRect.x / scale),
			y: parseInt(drawRect.y / scale),
			w: parseInt(drawRect.w / scale),
			h: parseInt(drawRect.h / scale),
		}
		const bbox = [
			rect.x,
			rect.y,
			rect.x + rect.w,
			rect.y + rect.h,
		]
		this.onAddDataPoint(bbox)
	}

	getDpi () {
		return document.getElementById("dpi").offsetHeight;
	}

	onAddDataPoint (bbox) {
		const newIndex = this.props.dataPoints.length;
		const newData = {
			index: newIndex,
			name: '',
			label: '',
			kind: 'bbox',
			separator: 'meta::dontseparate',
      page: this.state.pageNumber,
			bbox
		}
		const dataPoints = [...this.props.dataPoints, newData]
    this.props.setDataPoints(dataPoints)
		this.setState({ editing: null, lastAdded: newIndex })
		this.queryDataPoints()
    requestAnimationFrame(this.updateCanvas)
		this.openDialog()
		setTimeout(() => this.forceUpdate(), 1000)
	}

	onRemoveDataPoint (index) {
		const dataPoints = [...this.props.dataPoints]
		dataPoints.splice(index, 1)
    this.props.setDataPoints(dataPoints)
		this.setState({ editing: null })
		this.isDrag = false
		this.isDirty = false
    requestAnimationFrame(this.updateCanvas)
	}

	changeScale (by) {
		const scale = this.state.scale + by
		if (scale < 0.5 || scale > 2) {
			return
		}
		this.setState({ scale })
		setTimeout(() => {
			const el = document.getElementById("drawing")
			const page = document.getElementById("page-container")
			el.width = page.offsetWidth
			el.height = page.offsetHeight
			requestAnimationFrame(this.updateCanvas)	
		}, 10)
	}

  changePage (by) {
    const { pageNumber, numPages } = this.state;
    if (pageNumber + by <= 0 || pageNumber + by > numPages) {
      return
    }
    this.setState({
      pageNumber: pageNumber + by
    })
		setTimeout(() => {
			requestAnimationFrame(this.updateCanvas)	
		}, 100)
  }

	handleSubmitName (name) {
		if (!this.isNameValid())
			return;
		const dataPoints = this.updateDataPoint(this.state.lastAdded, { name })
    this.props.setDataPoints(dataPoints)
		this.setState({
			textModal: false,
			lastAdded: null,
			textInput: ""
		})
	}

	async queryDataPoints () {
    const patternId = this.getPatternId()
    const { dataPoints } = this.props;

    this.setState({ loading: true })
		try {
			const result = await api.post('patterns/query/' + patternId, {
        points: dataPoints
      })
			console.log(result)
      this.props.setDataQuery(result.data.points)
		} catch (e) {
			console.warn(e)
		} finally {
      this.setState({ loading: false })
      setTimeout(() => {
        requestAnimationFrame(this.updateCanvas)
      }, 500)
    }
	}

	async handleSubmitPattern () {
    const patternId = this.getPatternId()
    const { auth, dataPoints } = this.props;
    const { name } = this.state;

		this.setState({ loading: true })
		try {
			await api.post('patterns/save/' + patternId, {
				name,
				points: dataPoints,
        userKey: auth.logged ? auth.user.key : null
			})
			this.setState({ goNext: true })
		} catch (e) {
			console.warn(e)
		} finally {
			this.setState({ loading: false })
		}
	}

	isNameValid () {
		return this.state.textInput !== ''
	}

	isPatternValid () {
		return this.state.name !== '' && this.props.dataPoints.length > 0
	}

	openDialog() {
		this.setState({ textModal: true, textInput: "" })
	}

	closeDialog(removeLast = false) {
		if (removeLast) {
			this.onRemoveDataPoint(this.state.lastAdded)
		}
		this.setState({ textModal: false })
	}

	isDataQueryValid (index) {
		const value = this.props.dataQuery[index]
		return Array.isArray(value) ? Boolean(value && value.length > 0) : Boolean(value && value !== '')
	}

  goBack () {
    this.props.clearPattern();
    this.props.history.replace("/");
  }

	renderDialogContent (loading, lastAdded, dataQuery) {
		const dataPoint = this.props.dataPoints[lastAdded]

		if (loading || !dataQuery || !dataPoint) {
			return (
				<div className="flex flex-row justify-center mx-auto my-4 p-2">
					<CircularProgress />
				</div>
			)
		}

		const value = dataQuery[lastAdded]
		const isValueValid = this.isDataQueryValid(lastAdded)
		const handleChange = (e) => {
			const updDataPoint = this.updateDataPoint(lastAdded, { separator: e.target.value })
      this.props.setDataPoints(updDataPoint)
			setTimeout(() => {
				this.queryDataPoints()
			}, 100)
		}

		return (
			<React.Fragment>
				<DialogContent>
					<Typography variant="body2">
						This is what we identified from your selection:
					</Typography>
					<div className="my-4">
						{isValueValid ? (
							Array.isArray(value) ? (
								value.map((v, i) => <Chip key={i} label={v} variant="outlined" />)
							) : (
								<Chip label={value} variant="outlined" />
							)
						) : (
							<Chip label="No data was found" variant="outlined" color="secondary" />
						)}
					</div>
					<form autoComplete="off" onClick={() => false} onSubmit={(e) => {
						e.preventDefault();
						this.handleSubmitName(this.state.textInput)
						return false;
					}}>
						<FormControl>
							<InputLabel id="separator-label">Separator</InputLabel>
							<Select
								labelId="separator-label"
								id="separator-select"
								value={dataPoint.separator}
								onChange={handleChange}
							>
								<MenuItem value={'meta::dontseparate'}>Don't separate</MenuItem>
								<MenuItem value={' '}>Empty Space</MenuItem>
								<MenuItem value={','}>Comma</MenuItem>
								<MenuItem value={';'}>Semicolon</MenuItem>
								<MenuItem value={'\n'}>Line Break</MenuItem>
							</Select>
						</FormControl>
						<input type="hidden" autoComplete="false" />
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="Data name"
							type="text"
							value={this.state.textInput}
							onChange={(event) => this.setState({ textInput: event.target.value })}
							fullWidth
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button 
						onClick={() => this.closeDialog(true)}
						variant="outlined"
						color="primary"
					>
						CANCEL
					</Button>
					<Button 
						onClick={() => {
							this.handleSubmitName(this.state.textInput)
						}}
						variant="contained"
						color="primary"
						disabled={!this.isNameValid() || !isValueValid}
					>
						SAVE
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}

  renderDataPoints () {
    const { dataPoints } = this.props;
    const pages = _.uniq(dataPoints.map(point => point.page))
    console.log(pages)

    return (
      <List component="nav" aria-label="data-points" dense>
        {pages.map((page) => {
          return (
            <React.Fragment key={page}>
              <ListSubheader style={{ backgroundColor: 'white' }}>
                {`Page ${page}`}
              </ListSubheader>
              {this.renderPointsFromPage(page)}
            </React.Fragment>
          )
        })}
      </List>
    )
  }

  renderPointsFromPage (page) {
    const { dataPoints, dataQuery } = this.props;
		const deleting = this.state.editing === 'delete'

    return dataPoints.map((dataPoint, index) => {
      if (dataPoint.page !== page) {
        return null;
      }
      const queried = dataQuery ? dataQuery[index] : ''
      const isValid = this.isDataQueryValid(index)
      return (
        <ListItem key={index} className={deleting ? "bg-red-100 rounded" : ""}>
          <ListItemText 
            primary={dataPoint.name}
            secondary={queried}
          />
          {deleting ? (
            <ListItemIcon>
              <IconButton onClick={() => this.onRemoveDataPoint(index)}>
                <DeleteIcon color="secondary" />
              </IconButton>
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              {isValid ? (
                <CheckCircleOutlineIcon style={{ fontSize: 32, color: 'green' }} />
              ) : (
                <ErrorOutlineIcon style={{ fontSize: 32, color: 'orange' }} />
              )}
            </ListItemIcon>
          )}
        </ListItem>
      );
    })
  }
  
  render() {
    const patternId = this.getPatternId();
    const { dataPoints, nextPath } = this.props;
    const { goNext, loading, name, scale, pageNumber, numPages } = this.state;
		const editingLabel = this.state.editing === 'label'
		const editingBox = this.state.editing === 'bbox'
		const deleting = this.state.editing === 'delete'

		if (!patternId) {
			return <Redirect to={{ pathname: '/' }} />
		}

		if (goNext) {
			return <Redirect to={{ pathname: nextPath }} />
		}

    return (
			<div className="container mx-auto max-h-inherit p-2 h-full">
				{(deleting || editingBox) && (
					<div
						className="absolute inset-0 bg-black bg-opacity-70 z-50"
						onClick={() => this.setState({ editing: null })}
					/>
				)}
				<div className="flex flex-row items-stretch gap-6 max-h-inherith-full h-full">
          <div className="pb-2" style={{ flex: 3, zIndex: deleting ? 999 : 0 }}>
            <Paper variant="outlined" className="h-full shadow-lg">
              <div className="p-4 flex flex-col gap-2 h-full">
                <div className="p-2 m-1">
                  <div className="flex flex-row gap-2 items-center">
                    <IconButton aria-label="back" onClick={() => this.goBack()}>
                      <ArrowBackIcon />
                    </IconButton>
                    <div className="flex flex-col">
                      <h1 className="text-lg md:font-medium xl:text-3xl xl:font-normal">
                        Edit document pattern
                      </h1>
                      <span className="text-sm">
                        Draw boxes around the data you want to extract
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-baseline gap-3 mx-2 lg:mx-4 xl:mx-8">
                  <TextField
                    margin="dense"
                    id="name"
                    label="Pattern name"
                    type="text"
                    autoComplete='newpassword'
                    value={name}
                    onChange={(event) => this.setState({ name: event.target.value })}
                  />
                  <FormControl>
                    <InputLabel id="language-label">Idioma</InputLabel>
                    <Select
                      labelId="language-label"
                      id="language"
                      value={this.state.lang}
                      onChange={(event) => this.setState({ lang: event.target.value })}
                    >
                      <MenuItem value={'en'}>Inglês</MenuItem>
                      <MenuItem value={'fr'}>Francês</MenuItem>
                      <MenuItem value={'pt'}>Português</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="flex-1 h-full max-h-full overflow-auto my-1">
                  {dataPoints.length > 0 ? (
                    <Typography variant="subtitle1">
                      Data points
                    </Typography>
                  ) : (
                    <div className="m-4 text-center">
                      <Typography variant="h5">
                        Draw a box using the
                        <span className="mx-1">
                          <CreateIcon color="primary" fontSize="large" />
                        </span>
                        icon
                      </Typography>
                    </div>
                  )}
                  {this.renderDataPoints()}
                </div>
                <div className="flex flex-row gap-4 justify-between">
                  {loading ? (
                    <div className="mx-auto">
                      <CircularProgress />
                    </div>
                  ) : (
                    <React.Fragment>
                      <Button variant="outlined" color="primary" onClick={() => this.goBack()}>
                        CANCEL
                      </Button>
                      <Button
                        disabled={!this.isPatternValid()}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={() => this.handleSubmitPattern()}
                      >
                        SAVE PATTERN
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Paper>
          </div>          
					<div className="max-h-inherit max-w-4xl bg-graph" style={{ flex: 5 }}>
						<div className="flex flex-col w-full h-full max-h-inherit">
							<ScrollContainer
								className="flex flex-col overflow-auto w-full max-w-full"
                style={{ maxHeight: '90vh' }}
								hideScrollbars={false}
								ignoreElements={editingBox ? '*' : ''}
							>
								<div className={`relative mx-auto ${editingBox ? 'z-50' : ''}`}>
									<Document
										style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
										file={BACKEND_URL + "serve/" + patternId + "/original.pdf"}
										onLoadSuccess={(pdf) => {
											console.log(pdf)
											this.setState({ numPages: pdf.numPages })
										}}
										onItemClick={(args) => alert('Clicked an item from page ' + args + '!')}
									>
										<div id="page-container">
											<Page
												scale={scale}
												pageNumber={pageNumber}
												canvasRef={(ref) => {
													if (ref && !this.parentRef) {
														this.parentRef = ref;
														this.width = ref.width;
														this.height = ref.height;
														this.startX = ref.getBoundingClientRect().x
														this.startY = ref.getBoundingClientRect().y
														console.log('parent ref ready! ', this.parentRef.width, this.parentRef.height)
														setTimeout(() => {
															this.startCanvas()
														}, 1000);
													}
												}}
												renderTextLayer={!editingBox}
											/>
										</div>
									</Document>
									<canvas
										id="drawing"
										style={{
											zIndex: 30,
											display: editingLabel ? 'none' : 'block',
											position: 'absolute',
											top: 0, right: 0, left: 0, bottom: 0,
											cursor: editingBox ? 'crosshair' : 'inherit'
										}}
										width={this.width}
										height={this.height}
										ref={(ref) => {
											if (ref) {
												this.canvasRef = ref
											}
										}}
									/>
								</div>
							</ScrollContainer>
						</div>
					</div>
          <div className="flex">
            <Paper variant="outlined" className="self-center mb-2">
              <div className="flex flex-col gap-2 justify-center items-center p-1">
                <div className="flex flex-col flex-1 justify-end gap-6">
                  <IconButton color="primary" variant="outline" onClick={() => {
                    this.setState({ editing: 'bbox' });
                  }}>
                    <CreateIcon style={{ fontSize: 28 }} />
                  </IconButton>
                  <IconButton color="secondary" variant="outline" onClick={() => {
                    this.setState({ editing: 'delete' });
                  }}>
                    <DeleteIcon style={{ fontSize: 28 }} />
                  </IconButton>
                </div>
                <div className="my-3 mx-auto" style={{ width: '50%', border: '1px solid #777', borderRadius: 15 }} />
                <div className="flex flex-col flex-2 justify-center gap-2">
                  <IconButton onClick={() => this.changeScale(-0.2)}>
                    <ZoomOutIcon style={{ fontSize: 28 }} />
                  </IconButton>
                  <IconButton onClick={() => this.changeScale(0.2)}>
                    <ZoomInIcon style={{ fontSize: 28 }} />
                  </IconButton>
                </div>
                <div className="my-3 mx-auto" style={{ width: '50%', border: '1px solid #777', borderRadius: 15 }} />
                <div className="flex flex-col flex-1 justify-start items-center gap-1">
                  <IconButton onClick={() => this.changePage(1)}>
                    <KeyboardArrowUpIcon style={{ fontSize: 28 }} />
                  </IconButton>
                  <div className="flex flex-row items-center">
                    <span className="text-lg font-medium">{pageNumber}</span>
                    <span className="font-normal">/</span>
                    <span className="font-normal">{numPages}</span>
                  </div>
                  <IconButton onClick={() => this.changePage(-1)}>
                    <KeyboardArrowDownIcon style={{ fontSize: 28 }} />
                  </IconButton>
                </div>
              </div>
            </Paper>
          </div>
				</div>
				<Dialog
					aria-labelledby="form-dialog-title"
					onClose={(_, reason) => {
						if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
							this.setState({ textModal: false })
						}
					}}
					open={this.state.textModal}
					disableEscapeKeyDown
				>
					<DialogTitle id="form-dialog-title">
						Data Selection
					</DialogTitle>
					{this.renderDialogContent(this.state.loading, this.state.lastAdded, this.props.dataQuery)}
				</Dialog>
			</div>
		);
  }
}

const mapStateToProps = (state) => {
  return {
    patternId: state.pattern.patternId,
    dataPoints: state.pattern.dataPoints,
    dataQuery: state.pattern.dataQuery,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearPattern: () => dispatch(clearPattern()),
    setDataPoints: (points) => dispatch(setDataPoints(points)),
    setDataQuery: (points) => dispatch(setDataQuery(points))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditorScreen));
