import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Flags from 'country-flag-icons/react/3x2'

import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

// import SideMenu from './components/organisms/SideMenu'
import UserMenu from './components/atoms/UserMenu';

import Router from './routes'

import logo from './assets/icons/logo.svg'
import { logUser, logout } from './store/reducers/authReducer'
import { clearPattern } from './store/reducers/patternReducer';

import api from './api'
import Copyright from './components/atoms/Copyright';


function App () {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  let history = useHistory();

  React.useEffect(() => {
    const token = localStorage.getItem('pdfisher_token')
    console.log('starting app...')
    console.log('looking for session token ', token)

    const verify = async () => {
      try {
        const result = await api.post('/auth/verify', {
          token: token
        })
        if (result.data && result.data.success) {
          dispatch(logUser({
            user: result.data.user,
            token
          }))
        }
      } catch (e) {
        console.warn(e)
        localStorage.removeItem('pdfisher_token')
        dispatch(logout())
      } finally {
        setLoading(false)
      }
    }
    if (token) {
      verify()
    } else {
      setLoading(false)
    }
  // eslint-disable-next-line
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    localStorage.removeItem('pdfisher_token')
    dispatch(logout())
    dispatch(clearPattern())
    history.push('/')
  }

  if (loading) {
    return (
      <Container maxWidth="lg" className="h-screen">
        <div className="flex flex-row justify-center items-center h-full">
          <CircularProgress size="120px" />
        </div>
      </Container>
    )
  }

	return (
    <div className="flex flex-col justify-strech h-screen justify-between">
      <header className="shadow" style={{ maxHeight: '10vh' }}>
        <Container maxWidth="lg">
          <div className="flex flex-row justify-start items-center px-4 my-2">
            <Link replace to={{ pathname: "/" }} className="flex flex-row items-center gap-4">
              <img src={logo} className="w-16" alt="pdfisher-logo" />
              <span className="font-sans font-light text-3xl">
                pdfisher
              </span>
            </Link>
            <div className="flex-1">
            </div>
            <div className="flex flex-row justify-center">
              {/* <Tooltip title="Change language" arrow>
                <IconButton onClick={handleClick}>
                  <Flags.BR className="rounded-full" style={{ width: 36, height: 36 }} />
                </IconButton>
              </Tooltip>
              <Menu
                id="language-menu"
                className="mt-12"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                <MenuItem onClick={handleClose}>
                  EN
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  PT-BR
                </MenuItem>
              </Menu> */}
            </div>
            <div className="flex flex-row items-center mx-2">
              <UserMenu onLogout={onLogout} />
            </div>
          </div>
        </Container>
      </header>
      <main className="relative mx-2 overflow-hidden flex-1 max-h-inherit max-h-screen h-screen">
        <SnackbarProvider maxSnack={4}>
          <div className="w-full max-h-inherit h-full">
            <Router />
          </div>
        </SnackbarProvider>
        <div className="flex flex-row gap-4 absolute m-10 right-0 bottom-0">
          <Fab color="primary" aria-label="help">
            <span style={{ fontSize: 24 }}>
              ?
            </span>
          </Fab>
        </div>
      </main>
      <footer className="flex flex-row justify-center w-full mt-2 p-6 bg-primary-dark text-white">
        <Copyright />
      </footer>
		</div>
	)
}

export default App