import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import AttachmentIcon from '@material-ui/icons/Attachment';
import StorageIcon from '@material-ui/icons/Storage';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import GridOnIcon from '@material-ui/icons/GridOn';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import zapierSvg from '../assets/icons/zapier.svg'

import api, { BACKEND_URL } from '../api'
import { Redirect } from 'react-router-dom'
import Screen from '../components/wrappers/Screen'
import { LinearProgress } from '@material-ui/core'


function FinishScreen () {
	const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
	const [exportId, setExportId] = React.useState(null);
	const [exportType, setExportType] = React.useState('');
  let { id } = useParams();
  let { state } = useLocation();
  const reduxId = useSelector(state => state.pattern.patternId);
  const logged = useSelector(state => state.auth.logged);
  const patternId = id || reduxId;

	const handleUpload = async () => {
		const { files } = state;
		setLoading(true)
		try {
			var formData = new FormData();
			files.forEach((file) => {
				formData.append(`files`, file)
			})
			const result = await api.post(`patterns/export/${patternId}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			setExportId(result.data.exportId)
			setExportType(result.data.exportType)
		} catch (e) {
			console.warn(e)
			enqueueSnackbar('This didn\'t work. Please try again')
		} finally {
			setLoading(false)
		}
	}

	const handleDownload = () => {}

	React.useEffect(() => {
		if (state && state.files) {
			handleUpload()
		}
  // eslint-disable-next-line
	}, [])

  const iconByExportType = (type) => {
    if (type.toString().toLowerCase() === 'spreadsheet')
      return <GridOnIcon fontSize="large" style={{ color: 'green' }} />;
    
    if (type.toString().toLowerCase() === 'sql')
      return <StorageIcon fontSize="large" style={{ color: 'blue' }} />;
    
    return <InsertDriveFileIcon fontSize="large" style={{ color: 'blue' }} />;
  }

	const renderResult = () => {
		if (loading) {
			return (
				<div className="flex flex-col gap-5 text-center w-full mx-auto my-4 pb-4">
					{/* <CircularProgress size="4rem" /> */}
          <div>
            <h1 className="text-3xl">
              Your export is being generated
            </h1>
            <span>
              Don't close this tab. You'll receive your download link soon.
            </span>
          </div>
          <LinearProgress />
				</div>
			)
		}

		if (exportId !== null) {
			return (
				<React.Fragment>
					<Typography variant="h5">
						Your extraction is ready
					</Typography>
					<div className="flex flex-row gap-12 justify-between my-6">
						<div className="flex flex-1 flex-row gap-2 items-center justify-end">
							{iconByExportType(exportType)}
							<Typography variant="h6" style={{ fontWeight: 'normal' }}>
								{exportType}
							</Typography>
						</div>
						<div className="flex flex-1">
							<a href={`${BACKEND_URL}serve/${patternId}/${exportId}`} target="_blank" rel="noreferrer">
								<Button 
									style={{ width: "100%" }}
									variant="contained"
									color="primary"
									startIcon={<AttachmentIcon fontSize="large" />}
									onClick={handleDownload}
									>
									Download
								</Button>
							</a>
						</div>
					</div>
				</React.Fragment>
			)
		}

		return (
			<div>
				<span>Something did not work.</span>
			</div>
		)
	}

  const renderPreview = () => {
    return (
      <div>
      </div>
    )
  }

  const renderCTA = () => {
    if (logged) {
      return renderPreview();
    } else {
      return (
        <React.Fragment>
          <div className="mt-8 mb-4">
						<Typography variant="h4">
							Do you want to automate this process?
						</Typography>
					</div>
					<Typography variant="h6" style={{ fontWeight: 'normal' }}>
						Create an account and save this extraction template to reuse it any time you want.
					</Typography>
					<Typography variant="h6" style={{ fontWeight: 'normal' }}>
						And also integrate this extraction with a number of tools to create a automatic processing pipeline
						for your needs.
					</Typography>
					<div className="flex flex-row justify-center gap-8 my-6">
						<StorageIcon style={{ color: 'blue', fontSize: 64 }} />
						<img className="mr-1" src={zapierSvg} height="64" width="64" alt="zapier logo" />
					</div>
					<div className="flex flex-row justify-center gap-8 my-6">
            <Link to={{
              pathname: '/login',
              state: {
                register: true,
                savePattern: patternId
              }
            }}>
              <Button
                style={{ height: 55, fontSize: '1.3em', paddingLeft: 30, paddingRight: 30 }}
                variant="contained"
                color="secondary"
                startIcon={<AddIcon style={{ fontSize: '1.5em' }} />}
                >
                CREATE ACCOUNT
              </Button>
            </Link>
            <Link to={{
              pathname: '/login',
              state: { register: true }
            }}>
              <Button
                style={{ height: 55, fontSize: '1.3em', paddingLeft: 30, paddingRight: 30 }}
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon style={{ fontSize: '1.5em' }} />}
              >
                SAVE TEMPLATE
              </Button>
            </Link>
					</div>
        </React.Fragment>
      )
    }
  }

	if (!state || !state.files) {
		return (
			<Redirect to={{ pathname: '/upload' }} />
		)
	}

	return (
		<Screen>
			<div className="p-2">
				<div className="flex flex-col p-4">
					{renderResult()}
					<hr />
          {renderCTA()}
				</div>
			</div>
		</Screen>
	)
}

export default FinishScreen;
