import React from 'react'
import { useSelector } from 'react-redux'

import Container from '@material-ui/core/Container'
import SideMenu from '../organisms/SideMenu'

export default function Screen ({ children, ...props }) {
  const { logged } = useSelector(state => state.auth)


  if (logged) {
    return (
      <Container maxWidth="lg" {...props}>
        <div className="flex flex-row items-stretch">
          <nav className="flex flex-col justify-start mt-4 xl:mt-6 mr-1 xl:mr-2">
            <SideMenu />
          </nav>
          <div className="flex-1">
            {children}
          </div>
        </div>
      </Container>
    )
  }

  return (    
    <Container maxWidth="lg" {...props}>
      {children}
    </Container>
  )
}
