import React from 'react'

import Link from '@material-ui/core/Link'

function Copyright() {
  return (
    <p className="text-center">
      {'Copyright © '}
      <Link color="inherit" href="https://andremicheletti.github.io/" target="_black">
        Async Studio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </p>
  );
}

export default Copyright
