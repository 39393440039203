import { createSlice } from "@reduxjs/toolkit"

const patternSlice = createSlice({
  name: 'pattern',
  initialState: {
    patternId: null,
    dataPoints: [],
    dataQuery: []
  },
  reducers: {
    startPattern(state, action) {
      state.patternId = action.payload
      state.dataPoints = []
      state.dataQuery = []
    },
    setDataPoints(state, action) {
      state.dataPoints = action.payload
    },
    setDataQuery(state, action) {
      state.dataQuery = action.payload
    },
    clearPattern(state) {
      state.patternId = null
      state.dataPoints = []
      state.dataQuery = []
    }
  },
})

// Extract the action creators object and the reducer
const { actions, reducer } = patternSlice
// Extract and export each action creator by name
export const { startPattern, clearPattern, setDataPoints, setDataQuery } = actions
// Export the reducer, either as a default or named export
export default reducer
