import React from 'react'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import AttachmentIcon from '@material-ui/icons/Attachment';
import BackupIcon from '@material-ui/icons/Backup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CachedIcon from '@material-ui/icons/Cached';

import Screen from '../components/wrappers/Screen';

import api from '../api'
import { startPattern } from '../store/reducers/patternReducer'


function UploadScreen ({ rootPath = '' }) {
  const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const [selectedFile, setSelectedFile] = React.useState(null)
	const [uploading, setUploading] = React.useState(false)
  const { patternId } = useSelector(state => state.pattern)
  const logged = useSelector(state => state.auth.logged)
  let history = useHistory()

	const handleFile = (files) => {
		if (files && files[0]) {
			console.log(files[0])
			setSelectedFile(files[0])
		}
	}

	const handleUpload = async () => {
		if (!selectedFile) {
			enqueueSnackbar('Please select a file to upload.')
			return;
		}
		setUploading(true)
		try {
			var formData = new FormData();
			formData.append("file", selectedFile);
			const result = await api.post('patterns/upload', formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			console.log(result.data)
      const { key } = result.data.pattern
			dispatch(startPattern(key))
		} catch (e) {
			console.warn(e)
			if (e.response) {
				console.warn(e.response)
				enqueueSnackbar(e.response.data.status)
			} else if (e.request) {
				enqueueSnackbar('We couldn\'t make your request. Please check your connection')
			} else {
				enqueueSnackbar('This didn\'t work. Please try again')
			}			
		} finally {
			setUploading(false)
		}
	}

	if (patternId) {
		return (
			<Redirect to={{ pathname: `${rootPath}/editor/${patternId}`, }} />
		)
	}

	const renderContent = () => {
		if (uploading) {
			return (
				<div className="mx-auto my-2 pt-4">
					<CircularProgress />
				</div>
			)
		}

		return (
			<React.Fragment>
				<div className="mt-4">
					<Button 
						className="w-full h-40"
						variant="outlined"
						color={selectedFile ? "primary" : "secondary"}
						startIcon={<AttachmentIcon fontSize="large"/>}
						component="label"
					>
						{selectedFile ? selectedFile.name : 'CLICK TO SELECT A FILE'}
						<input type="file" hidden accept=".pdf" onChange={(e) => handleFile(e.target.files)} />
					</Button>
				</div>
				<div className="mt-2">
					<Button
						className="w-full"
						variant="contained"
						color="primary"
						startIcon={<BackupIcon fontSize="large" />}
						onClick={() => handleUpload()}
						disabled={!selectedFile}
						>
						Upload
					</Button>
				</div>
			</React.Fragment>
		)
	}

	return (
		<Screen>
      <div className="flex flex-col">
        <div className="xl:my-8 my-3" />
        <div className="flex flex-row items-center gap-4">
          {rootPath !== '' && (
            <IconButton aria-label="back" onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <h1 className="font-light text-5xl">
            {/* Criar modelo de extração de dados */}
            { logged ? 'Create data extraction model' : 'Extract data from multiple PDF files'}
          </h1>
        </div>
        <div className="mt-2">
          <p className="text-lg font-light">
            {/* Faça upload de um arquivo base para começar */}
            You can extract the same data from multiple pdf files at once
          </p>
          <p className="text-lg font-light">
            {/* Faça upload de um arquivo base para começar */}
            Upload a sample PDF file to start
          </p>
        </div>
        <div className="my-3" />
        {renderContent()}
        <div className="my-3" />
        <div className="grid grid-cols-3 gap-8">
          <div className="p-4 rounded shadow flex flex-col justify-center items-center bg-primary primary">
            <FindInPageIcon style={{ fontSize: '3rem' }} />
            <span className="text-lg text-center font-medium mt-2">
              {/* Escolha os dados de forma fácil */}
              Easily select your data
            </span>
            <span className="text-base font-light text-center">
              {/* Desenhe caixas sobre os dados que quer extrair do PDF usando a nossa ferramenta de fácil uso. */}
              Draw boxes over the data you want to extract from the PDF file using our simple tool.
            </span>
          </div>
          <div className="p-4 rounded shadow flex flex-col justify-center items-center bg-primary primary">
            <FileCopyIcon style={{ fontSize: '3rem' }} />
            <span className="text-lg text-center font-medium mt-2">
              {/* Extraia de vários arquivos */}
              Bulk extract from multiple files
            </span>
            <span className="text-base font-light text-center">
              {/* Você consegue extrair os mesmos dados de vários arquivos PDF e recebe-los numa planilha, por exemplo. */}
              You can extract the same set of data from multiple PDF files, and join them into a spreadsheet.
            </span>
          </div>
          <div className="p-4 rounded shadow flex flex-col justify-center items-center bg-primary primary">
            <CachedIcon style={{ fontSize: '3rem' }} />
            <span className="text-lg text-center font-medium mt-2">
              {/* Reutilize o modelo */}
              Reuse your extraction model
            </span>
            <span className="text-base font-light text-center">
              {/* Você pode salvar um modelo para extrair os mesmos dados novamente, apenas envie novos arquivos. */}
              You can save a extraction model to extract data from files at any time.
            </span>
          </div>
        </div>
      </div>
		</Screen>
	)
}

export default UploadScreen;
