import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import api from '../api'

import { logUser } from '../store/reducers/authReducer'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginScreen() {
  const { state } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { logged } = useSelector(state => state.auth)
	const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = React.useState(state && state.register ? 'register' : 'login');
  const [loading, setLoading] = React.useState(false);
  const [remember, setRemember] = React.useState(true);
  const [showPass, setShowPass] = React.useState(false);
  const [showNewPass, setShowNewPass] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');

  const emailError = () => {
    if (email === '')
      return 'Cannot be empty'
    if (!validateEmail(email))
      return 'Invalid email'
    return ''
  }
  const passwordError = (value, other = null) => {
    if (value === '')
      return 'Cannot be empty'
    if (value.length < 6)
      return 'Password must have at least 6 characters'
    if (other !== null && value !== other)
      return 'Passwords didn\'t match'
    return ''
  }

  const cantSubmit = () => {
    if (form === 'login') {
      return emailError() !== '' || passwordError(password) !== ''
    } else {
      return emailError() !== '' || passwordError(password) !== '' || passwordError(newPassword) !== ''
    }
  }

  const savePattern = (state && state.savePattern) || null;

  if (logged) {
    return <Redirect to={{ pathname: '/' }} />
  }

  const handleChangeForm = (to) => {
    setForm(to)
    setEmail('')
    setPassword('')
    setNewPassword('')
  }

  const handleLogin = async () => {
    setLoading(true)
    try {
      var formData = new FormData();
      formData.append('username', email)
      formData.append('password', password)
      const result = await api.post('/auth/token', formData, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
			})
      if (result && result.data) {
        if (remember) {
          localStorage.setItem('pdfisher_token', result.data.access_token)
        }
        dispatch(logUser({
          user: result.data.user,
          token: result.data.access_token
        }))
      } else {
        throw result
      }
    } catch (e) {
      console.warn(e)
      enqueueSnackbar('Wrong username or password.')
    } finally {
      setLoading(false)
    }
  }
  
  const handleRegister = async () => {
    if (email === '' || password === '') {
      enqueueSnackbar('Please fill all fields.')
      return
    }
    if (password !== newPassword) {
      enqueueSnackbar('The passwords didn\'t match.')
      return
    }
    setLoading(true)
    try {
      const result = await api.post('/auth/register', {
        email,
        password,
        savePattern
      })
      if (result.data.success) {
        handleLogin()
      }
    } catch (e) {
      console.warn(e)
    } finally {
      setLoading(false)
    }
  }

  const handleClick = form === 'login' ? handleLogin : handleRegister;

  const renderForm = () => {
    if (loading) {
      return (
        <div className="mx-auto my-10 pt-8">
          <CircularProgress size="68px" />
        </div>
      )
    }

    return (
      <div className={classes.form}>
        {savePattern && form === 'register' && (
          <div className="flex flex-row gap-1 mx-auto text-center">
            <SaveIcon color="primary" />
            <h2>
              Create your account now to save your pattern
            </h2>
          </div>
        )}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete={form === 'login' ? "email" : "new-password"}
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          error={emailError() !== ''}
          helperText={emailError()}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPass ? "text" : "password"}
          id="password"
          autoComplete={form === 'login' ? "current-password" : "new-password"}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordError(password) !== ''}
          helperText={passwordError(password)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}          
        />
        {form === 'register' && (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Repeat Password"
            type={showNewPass ? "text" : "password"}
            id="confirm-password"
            autoComplete="new-password" 
            onChange={(e) => setNewPassword(e.target.value)}
            error={passwordError(newPassword, password) !== ''}
            helperText={passwordError(newPassword, password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPass(!showNewPass)}
                  >
                    {showNewPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}   
          />
        )}
        {form === 'login' && (
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" onChange={(e) => setRemember(e.target.checked)} />}
            label="Remember me"
          />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleClick}
          disabled={cantSubmit()}
        >
          {form === 'login' ? 'Sign In' : 'Register'}
        </Button>
        <Grid container>
          {form === 'login' && (
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          )}
          <Grid item>
            {form === 'login' ? (
              <Link variant="body2" onClick={() => handleChangeForm('register')}>
                {"Don't have an account? Sign Up"}
              </Link>
            ) : (
              <Link variant="body2" onClick={() => handleChangeForm('login')}>
                {"Already have an account? Sign In"}
              </Link>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {form === 'login' ? 'Sign in' : 'Sign up'}
        </Typography>
        {renderForm()}
      </div>
    </Container>
  );
}