import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createTheme, ThemeProvider } from '@material-ui/core';
import { HashRouter } from 'react-router-dom'
import { orange } from '@material-ui/core/colors';
import configureStore from './store';

const theme = createTheme({
	status: {
		danger: orange[500]
	},
	palette: {
		primary: {
			light: '#00B2EE',
			main: '#00759C',
			dark: '#03506A'
		},
		secondary: {
			light: '#E5002A',
			main: '#B00020',
			dark: '#800017'
		},
		background: {
		}
	},
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#00759C33",
          "&:hover": {
            backgroundColor: "#00759C33",
          },
        },
      },
    }
  }
})

const store = configureStore()

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<HashRouter>
				<App />
			</HashRouter>
		</ThemeProvider>
	</Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
