import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import FindInPageIcon from '@material-ui/icons/FindInPage';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import emptySvg from '../assets/empty.svg';

import Screen from '../components/wrappers/Screen';
import api from '../api';


export default function PatternsScreen () {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState(true);
  const [patterns, setPatterns] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchPatterns = async () => {
      try {
        const result = await api.get('/patterns/me', { dispatch })
        setPatterns(result.data.patterns)
      } catch (e) {
        enqueueSnackbar('Something went wrong...')
      } finally {
        setLoading(false)
      }
    }
    fetchPatterns()
  // eslint-disable-next-line
  }, [])

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex flex-row justify-center pb-4">
          <CircularProgress />
        </div>
      )
    }

    if (!patterns || patterns.length <= 0) {
      return (
        <div className="flex flex-col mt-12 gap-8 pb-4 justify-center items-center mx-auto">
          <img src={emptySvg} alt="empty" height="300" width="300"/>
          <h2 className="text-2xl font-light">
            Create your first pattern to start
          </h2>
        </div>
      )
    }

    return (
      <List>
        {patterns.map((pattern) => {
          return (
            <React.Fragment key={pattern.key}>
              <Link to={{ pathname: '/patterns/editor/' + pattern.key }}>
                <ListItem key={pattern.key} button onClick={() => {}}>
                  <ListItemAvatar>
                    <FindInPageIcon style={{ color: 'gray' }} />
                  </ListItemAvatar>
                  <ListItemText>
                    <span className="text-xl">
                      {pattern.name}
                    </span>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton>
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Link>
            </React.Fragment>
          )
        })}
      </List>
    );
  }

  return (
    <Screen>
      <div className="mx-auto">
        <div className="p-6 flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <h1 className="text-3xl">
                Document Patterns
              </h1>
              <h4 className="text-base">
                Create extraction patterns to get data from PDF files
              </h4>
            </div>
            <div className="flex flex-col justify-center items-center">
              <Link to={{ pathname: '/patterns/new' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  NEW PATTERN
                </Button>
              </Link>
            </div>
          </div>
          <div className="flex-col mt-8">
            {renderContent()}
          </div>
        </div>
      </div>
    </Screen>
	)
}