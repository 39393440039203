import axios from 'axios'

import { logout } from './store/reducers/authReducer';

export const BACKEND_URL = "http://localhost:5000/"

const instance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 1 * 60 * 1000
});


// Request Interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('pdfisher_token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.config.dispatch) {
        console.log('unauthorized. logging out');
        error.response.config.dispatch(logout());
      } else {
        console.warn('axios was called without passing dispatch to config')
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
