import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import AccountCircle from '@material-ui/icons/AccountCircle';


export default function UserMenu ({ onLogout }) {
  const { logged } = useSelector(state => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!logged) {
    return (
      <React.Fragment>
        <Link to={{ pathname: 'login' }}>
          <Button
            color="primary"
            onClick={() => {}}
            >
            Log in
          </Button>
        </Link>
        <div className="mx-1" />
        <Link to={{ pathname: 'login', state: { register: true } }}>
          <Button
            color="primary"
            onClick={() => {}}
            variant="outlined"
          >
            Sign Up
          </Button>
        </Link>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AccountCircle 
          color="primary"
          style={{ fontSize: 32 }}
        />
      </IconButton>
      <Menu
        id="account-menu"
        className="mt-12"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        <MenuItem onClick={handleClose}>
          My Account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          Billing
        </MenuItem>
        <hr className="my-2" />
        <MenuItem onClick={() => {
          onLogout()
          handleClose()
        }}>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}