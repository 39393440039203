import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';


const mapPathToStep = {
  '/': 0,
  '/upload': 0,
  '/editor': 1,
  '/extract': 2,
  '/finish': 3
}

function SideMenu () {
  let { pathname } = useLocation();
  const { logged } = useSelector(state => state.auth)

	const colorIfStep = (needed) => {
    const step = mapPathToStep[pathname]
		return step >= needed ? '#00759C' : '#00759C33'
	}

  if (!logged) {
    // TODO: remove this. unused.
    return (
      <Grid 
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <AttachFileIcon fontSize="large" style={{ color: colorIfStep(0) }} />
        </Grid>
        <Grid item>
          <div style={{ background: colorIfStep(1), height: '20px', width: 1, padding: 1, borderRadius: 5 }} />
        </Grid>
        <Grid item>
          <PhotoFilterIcon fontSize="large" style={{ color: colorIfStep(1) }} />
        </Grid>
        <Grid item>
          <div style={{ background: colorIfStep(2), height: '20px', width: 1, padding: 1, borderRadius: 5 }} />
        </Grid>
        <Grid item>
          <FileCopyIcon fontSize="large" style={{ color: colorIfStep(2) }} />
        </Grid>
        <Grid item>
          <div style={{ background: colorIfStep(3), height: '20px', width: 1, padding: 1, borderRadius: 5 }} />
        </Grid>
        <Grid item>
          <CloudDownloadIcon fontSize="large" style={{ color: colorIfStep(3) }} />
        </Grid>
      </Grid>
    )
  }

  const selected = pathname.replace('/', '')
  const isSelected = (name) => selected.includes(name)

	return (
    <div className="px-1">
      <List>
        <Link to={{ pathname: '/extract' }} replace>
          <ListItem selected={isSelected('extract')} button>
            <ListItemIcon>
              <FileCopyIcon color={isSelected('extract') ? "primary" : "inherit"} />
            </ListItemIcon>
            <ListItemText>
              <span className={`${isSelected('extract') && 'primary'} font-medium`}>
                BULK EXTRACT
              </span>
            </ListItemText>
          </ListItem>
        </Link>
        <div className="my-2">
          <Divider />
        </div>
        <Link to={{ pathname: '/patterns' }} replace>
          <ListItem selected={isSelected('patterns')} button>
            <ListItemIcon>
              <FindInPageOutlinedIcon color={isSelected('patterns') ? "primary" : "inherit"} />
            </ListItemIcon>
            <ListItemText>
              <span className={`${isSelected('patterns') && 'primary'} font-medium`}>
                PATTERNS
              </span>
            </ListItemText>
          </ListItem>
        </Link>
        {/* <Link to={{ pathname: '/hooks' }} replace>
          <ListItem selected={isSelected('hooks')} button>
            <ListItemIcon>
              <BuildIcon color={isSelected('hooks') ? "primary" : "inherit"} />
            </ListItemIcon>
            <ListItemText>
              <span className={`${isSelected('hooks') && 'primary'} font-medium`}>
                HOOKS
              </span>
            </ListItemText>
          </ListItem>
        </Link> */}
        <div className="my-2">
          <Divider />
        </div>
        <Link to={{ pathname: '/settings' }} replace>
          <ListItem selected={isSelected('settings')} button>
            <ListItemIcon>
              <SettingsIcon color={isSelected('settings') ? "primary" : "inherit"} />
            </ListItemIcon>
            <ListItemText>
              <span className={`${isSelected('settings') && 'primary'} font-medium`}>
                ACCOUNT
              </span>
            </ListItemText>
          </ListItem>
        </Link>
        {/* <Link to={{ pathname: '/billing' }} replace>
          <ListItem selected={isSelected('billing')} button>
            <ListItemIcon>
              <AccountBalanceIcon color={isSelected('billing') ? "primary" : "inherit"} />
            </ListItemIcon>
            <ListItemText>
              <span className={`${isSelected('billing') && 'primary'} font-medium`}>
                BILLING
              </span>
            </ListItemText>
          </ListItem>
        </Link> */}
      </List>
    </div>
	)
}

export default SideMenu;
